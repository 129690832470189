@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body,
html {
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  opacity: 1;
}

body {
  margin: 0;
  padding: 0;
  /*  font-family: sans-serif;  */
  color: #252525;
}

a {
  text-decoration: none;
  color: #252525;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;
}

.settings-element-container {
  margin-top: 2px;
  margin-bottom: 2px;
}

.active {
  font-weight: bold;
}

.nav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
}

.nav li:first-child {
  padding-left: 0;
}

ul {
  padding-left: 0;
}

.list-item {
  list-style-type: none;
  text-decoration: none;
}

.large-list-item {
  list-style-type: none;
  text-decoration: none;
  border-left: 3px solid #dad7d7;
  padding-left: 5px;
  margin-bottom: 10px;
}

.masnahmen-list-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.field {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dad7d7;
  border-radius: 1px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.field-header {
  display: flex;
  flex-direction: row;
}

.edit-field {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.edit-icons {
  display: flex;
  margin-left: auto;
}

.icon {
  font-size: 27px;
  color: #697784;
}

.icon:hover {
  color: #00b5d6;
  cursor: pointer;
}

.icon_red {
  font-size: 27px;
  color: #697784;
}

.icon_red:hover {
  color: #6d0000;
  cursor: pointer;
}

.icon-button {
  background: transparent;
  border: none;
  outline: none;
  margin-top: -5px;
}

.fields-container {
  padding-left: 18px;
  padding-top: 18px;
  padding-right: 18px;
}

.dialog-container {
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
}

.dialog_button_container {
  float: right;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.settings_dialog_button_container {
  float: right;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 18px;
}

.dialog_button {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 12px;
}

.list-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.list-icons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

.list-element-header {
  display: flex;
  flex-direction: row;
}

.list-element-icons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

.list-element-header-item {
  margin-right: 10px;

  font-family: "Bento Sans", "Arial", Helvetica, sans-serif;
  font-size: 12;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 2.5;
  margin-bottom: 0.5;
  display: block;
  padding-top: 5px;
}

.list-element-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  margin-top: -3px;
}

.element-container-section {
  margin-top: 30px;
  margin-bottom: 18px;
}

.section-heading {
  font-family: "Benton Sans Medium", "Arial Black", Helvetica, sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 2.5;
  margin-bottom: 0.5;
  display: "block";
}

.element-container {
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.attachement_button_container {
  display: flex;
  margin-left: 10px;
  margin-bottom: 12px;
}

.attachement_button {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 12px;
}

.link-list-item {
  list-style-type: none;
  text-decoration: none;
  margin-top: 0.5;
  margin-bottom: 0.5;
  display: flex;
  flex-direction: row;
}

.link {
  font-size: 10pt;
}

.link-list-icon {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

.general-settings-row {
  margin-bottom: 12px;
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.tableau-label {
  font-family: sans-serif;
  font-size: 12px;
  color: #4f4f4f;
  display: inline-block;
  line-height: 21px;
}

.masnahmen-list-spacer {
  margin-left: 20px;
}

.separator {
  border: 1px solid #cbcbcb;
}

.full-width {
  width: 100%;
}

.dimension-item {
  list-style-type: none;
  text-decoration: none;
  margin-top: 0.5;
  margin-bottom: 0.5;
  display: flex;
  flex-direction: row;
}

.dimension-values {
  border-left: 2px solid #cbcbcb;
  margin-left: 5px;
  padding-left: 2px;
  margin-bottom: 10px;
}

.newer-version-note {
  background-color: #ee7600;
  padding: 10px;
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
}
